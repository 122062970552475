import React from "react"
import Layout from "../components/layout"
import Head from "../components/head"
import Section from "../components/section"

const GoogleMarketingPage = () => {
  return (
    <Layout title="Google Marketing" btnText="Get a quote" btnLink="/contact">
      <Head title="Google Marketing" />

      <Section
      isBlack="black"
        title="What we do"
        text={[
          `
Search engine marketing is our specialty. We make data-driven and highly optimised pay per click campaigns
.`,
        ]}
      />
      <Section  title="Get leads now" text={["The fastest way to start bringing in new customers to your business is with Google ads (ex Google AdWords).", `Easily calculate your return on investment (ROI).`, `Works great with big and small budgets.`]} />
      <Section
      isBlack="black"
        title="Client Retention"
        text={[
          `Our clients on average stay with us longer than 3 years (and the average is growing).`,`Even though they own the account and no contract ties them to us.`
        ]}
      />
    </Layout>
  )
}

export default GoogleMarketingPage
